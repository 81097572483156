canvas {
    position: absolute;
    background: black;
}


.randomize-button {
    cursor: pointer;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 50px;
    color: black;
    font-size: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}
